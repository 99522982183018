<template>
  <div class="customers">
    <div class="customers-input">
      <label v-if="label">{{ label }}</label>
      <div ref="search_box">
        <i class="ez-icon-search" :style="{'--bg': bg}"></i>
        <input type="search" v-model="searchTerm" :style="{'--bg': bg}"
               placeholder="Search customer by name, email or phone...">
        <input type="hidden" ref="hidden" :value="value" @input="updateText">
        <span v-if="error" ref="error" class="has-error animated headShake">{{ error }}</span>
      </div>
    </div>
    <div class="customers-results ez-slim-scroll" v-if="visible">
      <ul v-if="customers.length">
        <li v-for="customer in customers" :key="customer._id" @click="setCustomer(customer)">
          <div class="info">
            {{ customerName(customer) }}
          </div>
        </li>
      </ul>
      <div class="no-element" v-else>No elements</div>
    </div>
  </div>
</template>

<script>
import CustomerService from '@/services/customer.service';

const service = new CustomerService();

export default {
  name: "SelectCustomer",

  props: {
    value: {type: String, default: ''},
    label: {type: String},
    error: {type: String},
    bg: String
  },

  data() {
    return {
      searchTerm: '',
      visible: false,
      customers: [],
      customer: null
    }
  },

  async mounted() {
    this.listCustomers(false);

    if (this.value)
      this.$emit('input', '');

    if (this.error)
      this.$refs.error.innerHTML = '';
  },

  methods: {
    documentClick(e) {
      if (this.customer) {
        this.visible = false;
      } else {
        const target = e.target;
        const el = this.$refs.search_box;
        this.visible = !((el !== target) && el && !el.contains(target));
      }
    },

    updateText() {
      this.$emit('input', this.$refs.hidden.value);
    },

    customerName(customer) {
      return `${customer.FirstName} ${customer.LastName || ''}`;
    },

    async listCustomers(visibility = true) {
      const limit = 10;
      const skip = 0;

      const res = await service.list(limit, skip, this.searchTerm);
      if (res && !res.error) {
        this.customers = res.data.customers;
        this.visible = visibility;
      }
    },

    async setCustomer(customer) {
      this.$emit('change', customer);
      this.$emit('input', customer._id);
      this.customer = customer;
      this.searchTerm = `${customer.FirstName} ${customer.LastName || ''}`;
      this.visible = false;
    },
  },

  watch: {
    searchTerm(val) {
      if (this.customer) {
        if (val !== `${this.customer.FirstName} ${this.customer.LastName || ''}`) {
          this.customer = null;
        }
      } else {
        this.listCustomers(!this.customer);
      }

      if (!val) {
        this.$emit('input', '');
        this.$emit('change', null);
        this.customer = null;
      }
    },
  },

  created() {
    document.addEventListener('click', this.documentClick);
  },

  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
}
</script>

<style scoped lang="scss">
.customers {
  position: relative;

  &-input {
    position: relative;

    label {
      display: block;
      margin-bottom: 5px;
    }

    div {
      background: color(bg-white);
      height: 2.625rem;
      width: 100%;
      border: 1px solid #E7E8F2;
      display: flex;
      border-radius: 3px;
      overflow: hidden;
      transition: .15s ease-in-out;

      i {
        align-items: center;
        background: color(bg-white);
        color: #8798AD;
        display: flex;
        font-size: 1.2rem;
        justify-content: center;
        width: 2.7rem;
      }

      input {
        background: color(bg-white);
        border: none;
        color: #495057;
        flex: 1;
        font-size: 85%;
        height: 100%;
        padding-right: .5em;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #8798AD;
          font-size: .8rem;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #8798AD;
          font-size: .8rem;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #8798AD;
          font-size: .8rem;
        }
      }

      &:focus-within {
        box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
      }

      .has-error {
        position: absolute;
        bottom: -14px;
        font-size: .7rem;
        right: 5px;
        color: color(bg-red);
        font-weight: $font-medium;
      }

      @keyframes bounce {
        from,
        20%,
        53%,
        80%,
        to {
          -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }

        40%,
        43% {
          -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          -webkit-transform: translate3d(0, -30px, 0);
          transform: translate3d(0, -30px, 0);
        }

        70% {
          -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          -webkit-transform: translate3d(0, -15px, 0);
          transform: translate3d(0, -15px, 0);
        }

        90% {
          -webkit-transform: translate3d(0, -4px, 0);
          transform: translate3d(0, -4px, 0);
        }
      }

      .bounce {
        -webkit-animation-name: bounce;
        animation-name: bounce;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
      }
    }
  }

  &-results {
    background: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
    max-height: 200px;
    overflow: auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    border: 1px solid #E7E8F2;

    ul {
      list-style: none;

      li {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: space-between;
        overflow: hidden;
        padding: 5px 10px;

        &:last-child {
          border: none;
        }

        &:hover {
          background: color(bg-gray3);
          color: color(bg-black1);
        }

        .info {
          color: #495057;
          flex: 1;
          font-size: .8rem;
        }
      }
    }

    .no-element {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #495057;
      font-size: .8rem;
    }
  }
}
</style>