<template>
    <div class="agents">
        <div class="agents-input">
            <label v-if="label"> {{ label }} </label>
            <div ref="search_box">
                <i class="ez-icon-search" :style="{'--bg': bg}"></i>
                <input type="search" v-model="searchTerm" :style="{'--bg': bg}" placeholder="Search by Name, Email or Phone">
                <input type="hidden" ref="hidden" :value="value" @input="updateText">
                <span v-if="error" ref="error" class="has-error animated headShake">{{ error }}</span>
            </div>
        </div>
        <div class="agents-results ez-slim-scroll" v-if="visible && agents.length">
            <ul>
                <li v-for="agent in agents" :key="agent._id" @click="setAgent(agent)">
                    <div class="img">
                        <figure>
                            <img :src="agentAvatar(agent)" alt="">
                        </figure>
                    </div>
                    <div class="info">
                        {{ agentName(agent) }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import config from "@/config";
    import AgentService from '@/services/agent.service';

    const server_base = config[config.stage].ws_server;

    const service = new AgentService();

    export default {
        name: "SelectAgent",

        props: {
            value: {type: String, default: ''},
            label: {type: String},
            error: {type: String},
            bg: String
        },

        data() {
            return {
                searchTerm: '',
                visible: false,
                agents: []
            }
        },

        async mounted() {
            if (this.value)
                this.$emit('input', '');

            if (this.error)
                this.$refs.error.innerHTML = '';
        },

        methods: {
            documentClick(e) {
                const target = e.target;
                const el = this.$refs.search_box;
                this.visible = !((el !== target) && el && !el.contains(target));
            },

            updateText() {
                this.$emit('input', this.$refs.hidden.value);
            },

            agentAvatar(agent) {
                return `${server_base}/api/files/avatar/${agent.User._id}`;
            },

            agentName(agent) {
                return `${agent.User.FirstName} ${agent.User.LastName}`;
            },

            async listAgents() {
                const limit = 10;
                const skip = 0;

                const res = await service.list(limit, skip, this.searchTerm);
                if (res && !res.error) {
                    this.agents = res.data.agents;
                    this.visible = true;
                }
            },

            async setAgent(agent) {
                this.$emit('change', agent.User);
                this.$emit('input', agent.User._id);
                this.searchTerm = `${agent.User.FirstName} ${agent.User.LastName}`;
                this.visible = false;
                this.agents = [];
            },
        },

        watch: {
            searchTerm: {
                async handler(val) {
                    if (val)
                        await this.listAgents();
                    else {
                        this.$emit('input', '');
                        this.searchTerm = '';
                        this.visible = false;
                        this.agents = [];
                    }
                }
            },
        },

        created() {
            document.addEventListener('click', this.documentClick);
        },

        destroyed() {
            document.removeEventListener('click', this.documentClick);
        },
    }
</script>

<style scoped lang="scss">

    .agents {
        --bg: white;

        position: relative;

        &-input {
            position: relative;

            label {
                display: block;
                margin-bottom: 5px;
            }

            div {
                height: 37px;
                width: 100%;
                border: 1px solid #ced4da;
                display: flex;
                border-radius: 5px;
                overflow: hidden;
                transition: .15s ease-in-out;

                i {
                    align-items: center;
                    background: var(--bg);
                    color: #495057;
                    display: flex;
                    height: 100%;
                    font-size: 16px;
                    justify-content: center;
                    width: 44px;
                }

                input {
                    background: var(--bg);
                    border: none;
                    color: #495057;
                    flex: 1;
                    font-size: 85%;
                    height: 100%;
                    padding-right: .5em;
                }

                &:focus-within {
                    box-shadow: 0 3px 12px rgba(color(bg-gray2),.3);
                }

                .has-error {
                    position: absolute;
                    bottom: -14px;
                    font-size: .7rem;
                    right: 5px;
                    color: color(bg-red);
                    font-weight: $font-medium;
                }

                @keyframes bounce {
                    from,
                    20%,
                    53%,
                    80%,
                    to {
                        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                        -webkit-transform: translate3d(0, 0, 0);
                        transform: translate3d(0, 0, 0);
                    }

                    40%,
                    43% {
                        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                        -webkit-transform: translate3d(0, -30px, 0);
                        transform: translate3d(0, -30px, 0);
                    }

                    70% {
                        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                        -webkit-transform: translate3d(0, -15px, 0);
                        transform: translate3d(0, -15px, 0);
                    }

                    90% {
                        -webkit-transform: translate3d(0, -4px, 0);
                        transform: translate3d(0, -4px, 0);
                    }
                }

                .bounce {
                    -webkit-animation-name: bounce;
                    animation-name: bounce;
                    -webkit-transform-origin: center bottom;
                    transform-origin: center bottom;
                }
            }
        }

        &-results {
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.2);
            margin-top: 2px;
            max-height: 150px;
            overflow: auto;
            position: absolute;
            width: 100%;
            z-index: 1;

            ul {
                list-style: none;

                li {
                    align-items: center;
                    border-bottom: 1px solid color(border);
                    cursor: pointer;
                    display: flex;
                    height: 100%;
                    justify-content: space-between;
                    overflow: hidden;
                    padding: 5px 10px;

                    &:last-child {
                        border: none;
                    }

                    &:hover {
                        background: color(border);
                    }

                    .img {
                        margin-right: 10px;

                        figure {
                            background: color(bg-primary-light);
                            border-radius: 50%;
                            height: 25px;
                            overflow: hidden;
                            width: 25px;

                            img {
                                height: 100%;
                                object-fit: cover;
                                width: 100%;
                            }
                        }
                    }

                    .info {
                        color: #495057;
                        flex: 1;
                        font-size: .8rem;
                    }
                }
            }
        }
    }
</style>